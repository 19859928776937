import React from "react";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";
import { useRecordContext, useTranslate } from "react-admin";

interface LicenseListButtonProps {
  parentResource?: "organizations" | "experiences";
}

const LicenseListButton: React.FC<LicenseListButtonProps> = ({
  parentResource = "organizations",
}) => {
  const record = useRecordContext();
  const translate = useTranslate();

  return (
    <Button
      component={Link}
      to={`/${parentResource}/${record.id}/licenses`}
      variant='text'
      color='primary'
    >
      {translate("resources.licenses")}
    </Button>
  );
};

export default LicenseListButton;

import { Breadcrumbs, Button } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { theme } from "../../global/styles";
import dataProvider from "../../data-providers/data-provider";
import { useEffect, useState } from "react";
import { useTranslate } from "react-admin";

interface BreadcrumbLinkProps {
  to: string;
  active?: boolean;
  children: React.ReactNode;
}

const BreadcrumbLink: React.FC<BreadcrumbLinkProps> = ({
  children,
  to,
  active,
  ...props
}) => {
  return (
    <Button
      component={Link}
      to={to}
      sx={{
        color: "white",
        fontWeight: "bold",
        textDecoration: active ? "underline !important" : "none",
        backgroundColor: active ? theme.palette.primary.dark : "none",
        "&:hover": {
          color: "white",
          backgroundColor: theme.palette.primary.dark,
        },
        width: "fit-content",
      }}
      {...props}
    >
      {children}
    </Button>
  );
};

BreadcrumbLink.defaultProps = {
  active: false,
};

interface TranslatedPathItemIdProps {
  id: number;
  resource: string;
}

const TranslatedPathItemId: React.FC<TranslatedPathItemIdProps> = ({
  id,
  resource,
}) => {
  const [data, setData] = useState<any>(null);

  useEffect(() => {
    (async () => {
      const response = await dataProvider.getOne(resource, { id });
      setData(response.data);
    })();
  }, [id, resource]);

  if (!data) return <span>{id}</span>;
  if (resource === "users")
    return <span>{`${data.first_name} ${data.last_name}`}</span>;
  return <span>{data.name ?? id}</span>;
};

interface TranslatedPathItemProps {
  item: string;
  prev?: string;
}

const TranslatedPathItem: React.FC<TranslatedPathItemProps> = ({
  item,
  prev,
}) => {
  const translate = useTranslate();

  switch (item.toLowerCase()) {
    case "dashboard":
    case "experiences":
    case "users":
    case "licenses":
    case "tags":
    case "organizations":
    case "experience-groups":
    case "create":
    case "profile":
    case "service-licenses":
    case "devices":
      return <span>{translate(`resources.${item.toLowerCase()}`)}</span>;
    default:
      // translate if : is a number, previous is not a number and previous is not create
      if (
        !isNaN(Number(item)) &&
        prev &&
        isNaN(Number(prev)) &&
        prev !== "create"
      )
        return <TranslatedPathItemId id={Number(item)} resource={prev} />;
      return <span>{item}</span>;
  }
};

const Breadcrumb = () => {
  const location = useLocation();
  const translate = useTranslate();

  const paths = location.pathname
    .split("/")
    .filter((path) => path !== "")
    // filter out consecutive ids (= tabs)
    .filter((path, index, array) => {
      if (index === 0) return true;
      if (isNaN(Number(path)) || isNaN(Number(array[index - 1]))) return true;
      return false;
    });

  const pathTranslations = paths.map((path, index) => (
    <TranslatedPathItem item={path} prev={paths?.[index - 1]} />
  ));

  return (
    <Breadcrumbs
      aria-label='breadcrumb'
      sx={{
        color: "white",
        fontWeight: "bold",
      }}
    >
      <BreadcrumbLink to='/' active={paths.length === 0}>
        {translate("resources.dashboard")}
      </BreadcrumbLink>
      {paths.map((path, index) => (
        <BreadcrumbLink
          to={`/${paths.slice(0, index + 1).join("/")}`}
          active={index === paths.length - 1}
        >
          {pathTranslations[index]}
        </BreadcrumbLink>
      ))}
    </Breadcrumbs>
  );
};

export default Breadcrumb;

import {DeleteButton, SaveButton, Toolbar, ToolbarProps, useRecordContext} from "react-admin";
import React from "react";
import {Stack} from "@mui/material";

interface CustomToolbarProps extends ToolbarProps {
    deleteRedirect?: string;
}

const CustomSimpleToolbar: React.FC<CustomToolbarProps> = ({deleteRedirect, ...props}) => {
    const record = useRecordContext();
    return (
        <Toolbar {...props}>
            <Stack direction="row" gap={2} justifyContent={record ? "space-between" : "flex-end"} py={2} width={"100%"}>
                {record &&
                    <DeleteButton redirect={deleteRedirect} mutationMode={"undoable"} icon={null}
                                  color={"error"} size={"medium"}/>}
                <SaveButton icon={null} variant={"contained"} size={"medium"} alwaysEnable/>
            </Stack>
        </Toolbar>
    )
};

export default CustomSimpleToolbar;
import {createTheme} from "@mui/material";
import "@fontsource/montserrat";

const palette = {
    background: {
        default: "#f2f4f5",
    },
    primary: {
        main: "#0C9AF2",
        light: "#abdbff",
        dark: "#0171b0",
        contrastText: "#ffffff",
    },
    secondary: {
        light: "#6ec6ff",
        main: "#2196f3",
        dark: "#0069c0",
        contrastText: "#fff",
    },
    error: {
        main: "#f44336",
        light: "#e57373",
        dark: "#d32f2f",
        contrastText: "#fff",
    },
};

const glassSx = {
    // background: "rgba(255, 255, 255, 0.5)",
    // borderRadius: "16px",
    boxShadow: "0 4px 4px rgba(0, 0, 0, 0.15)",
    // backdropFilter: "blur(8px)",
    // "-webkit-backdrop-filter": "blur(8px)",
    background: "rgba(255, 255, 255, 1)",
};

const _theme = createTheme({
    palette: {
        ...palette,
    },
    spacing: 8,
    shape: {borderRadius: 16},
    typography: {
        fontFamily: "Montserrat",
        h1: {
            fontWeight: "bold",
        },
        h2: {
            fontWeight: "bold",
        },
        h3: {
            fontWeight: "bold",
        },
        h4: {
            fontWeight: "bold",
        },
        h5: {
            fontWeight: "bold",
        },
        h6: {
            fontWeight: "bold",
        },
        button: {
            textTransform: "none",
            fontSize: "1rem",
        },
        caption: {
            fontWeight: "bold",
        },
        overline: {
            fontWeight: "bold",
        },
        subtitle2: {},
        subtitle1: {},
        body2: {},
        allVariants: {
            fontWeight: 600,
        },
        body1: {
            fontSize: "1rem",
        },
    },
    components: {
        MuiTextField: {
            defaultProps: {
                variant: "outlined" as const,
                margin: "normal" as const,
                size: "medium" as const,
            },
        },
        // MuiInputBase => add glass effect + radius
        MuiInputBase: {
            styleOverrides: {
                root: {
                    ...glassSx,
                    borderRadius: "16px",
                },
            },
        },
        MuiFormControl: {
            defaultProps: {
                variant: "outlined" as const,
                margin: "normal" as const,
                size: "medium" as const,
            },
        },
        // override MuiButton (contained) default style
        MuiButton: {
            styleOverrides: {
                containedPrimary: {
                    "&:hover": {
                        color: palette.primary.contrastText,
                        backgroundColor: palette.primary.dark,
                    },
                },
                textPrimary: {
                    "&:hover": {
                        color: palette.primary.contrastText,
                        backgroundColor: palette.primary.dark,
                    },
                },
                containedError: {
                    color: "white !important",
                    "&:hover": {
                        backgroundColor: "darkred !important",
                    },
                },
            },
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    "&:hover": {
                        color: palette.primary.main,
                    },
                    "&.Mui-selected": {
                        color: palette.primary.main,
                    }
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    "&:hover": {
                        color: palette.primary.main,
                    },
                },
            },
        },
        // Paper => add glass effect
        MuiPaper: {
            styleOverrides: {
                // root: glassSx,
            },
        },
        // table head : add glass effect
        MuiTableHead: {
            styleOverrides: {
                // root: glassSx,
            },
        },
        MuiListItem: {
            styleOverrides: {
                // root: glassSx,
            },
        },
        // MuiTable => grey background, blue header, width 20em
        MuiTable: {
            styleOverrides: {
                root: {
                    // backgroundColor: palette.background.default,
                },
            },
        },
        MuiTableSortLabel: {
            styleOverrides: {
                root: {
                    color: "white !important",
                },
                active: {
                    color: "white !important",
                },
                icon: {
                    color: "white !important",
                }
            },
        },
        MuiTablePagination: {
            styleOverrides: {
                selectLabel: {
                    margin: 0,
                },
                displayedRows: {
                    margin: 0,
                }
            },
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    borderBottom: "none",
                    backgroundColor: palette.background.default,
                    borderRadius: "0px 0px 16px 16px",
                },
            },
        },
        MuiStepper: {
            // Mui-completed => green
            styleOverrides: {},
        },
        MuiStepIcon: {
            styleOverrides: {
                root: {
                    //completed
                    "&.Mui-completed": {
                        color: "green",
                    },
                },
            },
        },
        MuiStepLabel: {
            styleOverrides: {
                label: {
                    "&.Mui-completed": {
                        color: "green",
                        fontWeight: "bold",
                    },
                }
            },
        },
        MuiDrawer: {
            styleOverrides: {
                root: {
                    // background: "white",
                    // boxShadow: "0 4px 4px rgba(0, 0, 0, 0.15)",
                    // height: "100em",
                    // borderRadius: "0 0 16px 16px",
                    // padding: "1em",
                    // margin: "2em 1em",
                },
                docked: {
                    // MuiDrawer-docked-RaSidebar-root
                    "&.MuiDrawer-docked": {
                        height: "unset"
                    }
                }
            }
        },
        MuiToggleButton: {
            styleOverrides: {
                root: {
                    backgroundColor: palette.background.default,
                    border: "none",
                    "&.Mui-selected": {
                        backgroundColor: palette.primary.main,
                        color: "white",
                        "&:hover": {
                            backgroundColor: palette.primary.dark,
                        },
                    }
                },
            },
        },
        MuiToggleButtonGroup: {
            styleOverrides: {
                root: {
                    backgroundColor: palette.background.default,
                },
                grouped: {
                    borderRadius: "16px !important",
                    border: "none",
                    margin: "0 !important",
                }
            },
        },
        MuiToolbar: {
            styleOverrides: {
                root: {
                    backgroundColor: "inherit !important",
                },
            }
        }
    },
});

export const theme: any = {
    ..._theme,
    components: {
        ..._theme.components,
        RaDatagrid: {
            styleOverrides: {
                root: {
                    backgroundColor: palette.background.default,
                    borderRadius: "16px",
                    // height: "33em",
                    // overflow: "auto",
                    "& .RaDatagrid-headerCell": {
                        backgroundColor: palette.primary.main,
                        color: "white !important",
                        fontWeight: "bold",
                        borderRadius: "0",
                        // first cell
                        "&:first-child": {
                            borderRadius: "16px 0px 0px 16px",
                        },
                        // last cell
                        "&:last-child": {
                            borderRadius: "0 16px 16px 0",
                        },
                    },
                }
            }
        },
        RaList: {
            styleOverrides: {
                root: {
                    // padding: "2em 4em 2em 4em",
                    // background: "white",
                    // borderRadius: "16px",
                    // boxShadow: "0 4px 4px rgba(0, 0, 0, 0.15)",
                    // marginBottom: "4em",
                    // marginTop: "1em",
                }
            },
        },
        RaSidebar: {
            styleOverrides: {
                root: {
                    // background: "white",
                    // boxShadow: "0 4px 4px rgba(0, 0, 0, 0.15)",
                    // height: "100vh",
                    // borderRadius: "16px",
                    // padding: "1em",
                    // margin: "2em 1em",
                    // height: "100vh",
                },
            }
        },
        RaLayout: {
            styleOverrides: {
                root: {
                    background: palette.background.default,
                    paddingTop: "1em",
                    // "& .RaLayout-content": {
                    //     padding: "2em 4em",
                    // }
                },
            }
        },
        RaMenuItemLink: {
            styleOverrides: {
                root: {
                    "&:hover": {
                        color: palette.primary.main,
                    },
                    "&.RaMenuItemLink-active": {
                        color: palette.primary.main,
                        "& .RaMenuItemLink-icon": {
                            color: palette.primary.main,
                        },
                    },
                },

            }
        },
        RaBulkActionsToolbar: {
            styleOverrides: {
                root: {
                    "& .RaBulkActionsToolbar-toolbar": {
                        padding: "0",
                    }
                },
            }
        }
    }
};

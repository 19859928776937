import React from "react";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";
import { useRecordContext, useTranslate } from "react-admin";

const UserListButton = () => {
  const record = useRecordContext();
  const translate = useTranslate();

  return (
    <Button
      component={Link}
      to={`/organizations/${record.id}/users`}
      variant='text'
      color='primary'
    >
      {translate("resources.users")}
    </Button>
  );
};

export default UserListButton;

import makeStyles from "@mui/styles/makeStyles";
import {
  Loading,
  MenuItemLink,
  usePermissions,
  useTranslate,
} from "react-admin";
import { hasAccess } from "../../global/permissions";
import { Divider, Stack } from "@mui/material";
import AppsIcon from "@mui/icons-material/Apps";
import BusinessIcon from "@mui/icons-material/Business";
import PersonIcon from "@mui/icons-material/Person";
import DashboardIcon from "@mui/icons-material/Dashboard";
import LabelIcon from "@mui/icons-material/Label";
import WorkspacesIcon from "@mui/icons-material/Workspaces";
import GroupIcon from "@mui/icons-material/Group";
import LogoutButton from "./Buttons/LogoutButton";
import DevicesIcon from "@mui/icons-material/Devices";

const useStyles = makeStyles({
  menuItem: {
    margin: 0,
    padding: "8px 16px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: "1px",
    fontSize: ".9rem",
  },
});

export const CustomMenu = ({ onMenuClick }) => {
  const { permissions } = usePermissions();
  const classes = useStyles();
  const { id: organizationId } = JSON.parse(
    localStorage.getItem("organization") || "{}"
  );
  const translate = useTranslate();

  if (!permissions) return <Loading />;

  return (
    <Stack gap={2} sx={{ background: "white", height: "100vh" }}>
      <img
        src={"/Icone_App_Home.png"}
        alt={"logo"}
        style={{
          width: "140px",
          padding: "1em",
          alignSelf: "center",
          borderRadius: "32px",
        }}
      />

      {/* Dashboard */}
      <MenuItemLink
        className={classes.menuItem}
        key='dashboard'
        to='/'
        primaryText={translate("resources.dashboard")}
        leftIcon={<DashboardIcon />}
      />

      {/* Profile */}
      <MenuItemLink
        className={classes.menuItem}
        key=''
        to='/profile'
        primaryText={translate("resources.my_account")}
        leftIcon={<PersonIcon />}
      />

      <MenuItemLink
        className={classes.menuItem}
        key='my-organization'
        to={`/organizations/${organizationId}`}
        primaryText={translate("resources.my_organization")}
        leftIcon={<BusinessIcon />}
      />

      <MenuItemLink
        className={classes.menuItem}
        key='my-users'
        to={`/organizations/${organizationId}/users`}
        primaryText={translate("resources.my_users")}
        leftIcon={<GroupIcon />}
      />

      {/* Experiences */}
      {hasAccess({ name: "experiences", crudType: "list" }, permissions) && (
        <MenuItemLink
          className={classes.menuItem}
          key='experiences'
          to='/experiences'
          primaryText={translate("resources.experiences")}
          leftIcon={<AppsIcon />}
        />
      )}

      {/* Experience Groups */}
      {hasAccess(
        { name: "experience-groups", crudType: "list" },
        permissions
      ) && (
        <MenuItemLink
          className={classes.menuItem}
          key='experience-groups'
          to='/experience-groups'
          primaryText={translate("resources.experience-groups")}
          leftIcon={<WorkspacesIcon />}
        />
      )}

      {/* Organizations */}
      {hasAccess({ name: "organizations", crudType: "list" }, permissions) && (
        <MenuItemLink
          className={classes.menuItem}
          key='organizations'
          to='/organizations'
          primaryText={translate("resources.organizations")}
          leftIcon={<BusinessIcon />}
        />
      )}

      {/* Tags */}
      {hasAccess({ name: "tags", crudType: "list" }, permissions) && (
        <MenuItemLink
          className={classes.menuItem}
          key='tags'
          to='/tags'
          primaryText={translate("resources.tags")}
          leftIcon={<LabelIcon />}
        />
      )}

      {/* Devices */}
      {hasAccess({ name: "devices", crudType: "list" }, permissions) && (
        <MenuItemLink
          className={classes.menuItem}
          key='devices'
          to='/devices'
          primaryText={translate("resources.devices")}
          leftIcon={<DevicesIcon />}
        />
      )}

      {/*/!* Tags *!/*/}
      {/*{hasAccess({name: "service-licenses", crudType: "list"}, permissions) && <MenuItemLink*/}
      {/*    className={classes.menuItem}*/}
      {/*    key='service-licenses'*/}
      {/*    to={`/organizations/${organizationId}/service-licenses`}*/}
      {/*    primaryText={translate('resources.service-licenses')}*/}
      {/*    leftIcon={<KeyIcon/>}*/}
      {/*/>}*/}

      <Divider />

      <LogoutButton />
    </Stack>
  );
};

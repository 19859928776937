import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {makeStyles} from "@mui/styles";
import {Typography} from "@mui/material";

interface CustomTitleProps {
  titles: string[];
}

const useStyles = makeStyles({
  container: {
    display: "flex",
    alignItems: "center",
    height: ".7em",
    width: "100%",
  },
  h5: {
    margin: "0",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  icon: {
    // fontSize: "1.4em",
  },
});

export const CustomTitle = ({ titles }: CustomTitleProps) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      {titles.map((t, index, self) => (
        <>
          <Typography variant="subtitle1" key={index}>{t}</Typography>
          {index < self.length - 1 && (
              <ChevronRightIcon fontSize={"medium"} color='inherit' key={t} className={classes.icon}/>
          )}
        </>
      ))}
    </div>
  );
};

import {Create} from "react-admin";
import OrganizationForm from "./OrganizationForm";
import {organizationFormTransform} from "./OrganizationEdit";

const OrganizationCreate = (props) => {
  return (
      <Create transform={organizationFormTransform}>
      <OrganizationForm {...props} />
    </Create>
  );
};

export default OrganizationCreate;

import * as React from "react";
import {AppBar, usePermissions, useRedirect} from "react-admin";
import makeStyles from "@mui/styles/makeStyles";
import logo from "../../assets/images/UNIVR-BLANC.svg";
import {Box, useMediaQuery} from "@mui/material";
import Breadcrumb from "./Breadcrumb";
import licenseService from "../../services/license-service";
import NotificationButton from "../Notification/NotificationButton";

const useStyles = makeStyles({
  toolbar: {
    display: "grid",
    gridTemplateColumns: "3fr 1fr 3fr",
    width: "100%",
    background: "none",
  },
  logo: {
    justifySelf: "center",
    width: "64px",
  },
  title: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
});

const CustomAppBar = (props) => {
  // bp: breakpoint
  const matches = useMediaQuery("(min-width:600px)");
  const redirect = useRedirect();
  const {permissions} = usePermissions();
  const isAdmin = permissions?.includes("admin");

  const classes = useStyles();

  const [expiredLicenses, setExpiredLicenses] = React.useState<object[]>([]);

  React.useEffect(() => {
    const fetchExpiredLicenses = async () => {
      const res = await licenseService.fetchExpiredLicenses();

      if (res.error)
        console.error(res.error);
      else
        setExpiredLicenses(res);
    };

    fetchExpiredLicenses();
  }, []);

  return (
    <AppBar
      userMenu={false}
      sx={{
        background: "radial-gradient(circle farthest-corner at 16.5% 28.1%, #0171b0 0%, #0C9AF2 90%)",
        height: "64px",
        display: "flex",
        justifyContent: "center",
        borderRadius: "0 0 16px 16px",
        boxShadow: "rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px",
      }}
    >
      {matches && (
        <Box
          ml={4}
          mr={8}
          sx={{
            cursor: "pointer",
            transition: "opacity 0.2s ease-in-out",
            opacity: 1,
            "&:hover": {
              opacity: 0.9,
            }
          }}
          onClick={() => redirect("/")}
        >
          <img src={logo} alt='logo' className={classes.logo} />
        </Box>
      )}
      <Box flex={1}>
        <Breadcrumb />
      </Box>
      {isAdmin && <NotificationButton notifications={expiredLicenses}/>}
    </AppBar>
  );
};

export default CustomAppBar;

import React, { useState } from 'react';
import Badge from '@mui/material/Badge';
import IconButton from '@mui/material/IconButton';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

const NotificationButton = ({ notifications = [] }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const navigate = useNavigate();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleItemClick = (url:string) => {
        navigate(url);
        handleClose();
    }

    return (
        <div>
            <IconButton color="inherit" onClick={handleClick}>
                <Badge badgeContent={notifications.length} color='error'>
                    <NotificationsIcon />
                </Badge>
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        maxHeight: 200,
                    },
                }}
            >
                <List>
                    {notifications.length === 0 ? (
                        <MenuItem onClick={handleClose}>Pas de notification.</MenuItem>
                    ) : (
                      <div>
                        <MenuItem style={{textDecoration: 'underline', color: 'black', backgroundColor: 'white'}}>
                            Licences arrivant à expiration:
                        </MenuItem>
                        {notifications.map((notification, index) => {
                            const title = `${notification?.organization?.name} - ${notification?.experience?.name} - ${moment(notification?.ended_at).format('DD/MM/YYYY')}`;
                            const url = `/organizations/${notification?.organization?.id}/licenses/${notification?.id}`;

                            return (
                                <MenuItem  key={index} onClick={() => {handleItemClick(url)}}>
                                    <ListItemText primary={title} />
                                </MenuItem>
                            );
                        })
                      }
                      </div>
                    )}
                </List>
            </Menu>
        </div>
    );
};

export default NotificationButton;

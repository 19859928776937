import axios from "axios";
import {Box, Typography} from "@mui/material";
import FlashingDot from "../components/Notification/FlashingDot";
import moment from "moment";

/* eslint-disable default-case */
/* eslint-disable import/no-anonymous-default-export */

export default {
  renderEndDate: (record) => {
      const isVeryLongLicense = moment(record.ended_at).isSameOrAfter(moment().add(50, "years"));
    return (
      <Box display="flex" alignItems="center" gap={1} justifyContent="center">
          {!record || record.type === "unlimited" || isVeryLongLicense ? (
          <>
            <Typography variant="body2">-</Typography>
            {record?.validity === "expired" && <FlashingDot />}
          </>
        ) : (
          <>
            <Typography variant="body2">
              {record.ended_at?.split("-").reverse().join("/")}
            </Typography>
            {record?.validity === "expired" && <FlashingDot />}
          </>
        )}
      </Box>
    );
  },
  transformLicense: (data) => {
    const start = new Date(data.started_at);
    data.started_at = start.toISOString().split("T")[0];

    // based on type
    switch (data.type) {
      case "6 months":
        const end1 = new Date(start.setMonth(start.getMonth() + 6));
        data.ended_at = end1.toISOString().split("T")[0];
        break;
      case "1 year":
        const end2 = new Date(start.setFullYear(start.getFullYear() + 1));
        data.ended_at = end2.toISOString().split("T")[0];
        break;
      case "unlimited":
        const end3 = new Date(start.setFullYear(start.getFullYear() + 100));
        data.ended_at = end3.toISOString().split("T")[0];
        break;
    }

    return data;
  },
  fetchExpiredLicenses: async () => {
    try {
      const token = JSON.parse(localStorage.getItem("token")).access_token;
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/licenses/expired`, {
        headers: {
          "Authorization": `Bearer ${token}`,
        }
      });

      return res.data;
    } catch (error) {
      console.log(error);

      return {error: error.message};
    }
  }
};

import PropTypes from "prop-types";
import {Box, Button, Checkbox, CircularProgress, FormControlLabel, Link, TextField,} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import {useLogin, useNotify, useRedirect, useSafeSetState, useTranslate} from "react-admin";
import {useEffect, useState} from "react";

const useStyles = makeStyles(
  (theme) => ({
    form: {
      display: "flex",
      flexDirection: "column",
      height: "100%",
      margin: "6em 2em 1em 2em",
    },
    innerForm: {
      flex: "1",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-around",
    },
    input: {
      flex: "1",
    },
    cardActions: {
      display: "block",
      padding: "8px 0",
      alignItems: "initial",
      flex: "initial",
    },
    rememberMe: {
      // padding: "1em 0"
    },
    button: {
      // margin: "0em 4em",
      width: "100%",
    },
    icon: {
      marginRight: "0.5em",
    },
  }),
  { name: "RaLoginForm" }
);

const Input = ({ meta: { touched, error }, input: inputProps, ...props }) => (
  <TextField
    error={!!(touched && error)}
    helperText={touched && error}
    {...inputProps}
    {...props}
    fullWidth
  />
);

export const CustomLoginForm = (props) => {
  const [loading, setLoading] = useSafeSetState(false);
  const [rememberMe, setRememberMe] = useState(true);
  const login = useLogin();
  const notify = useNotify();
  const redirect = useRedirect();
    const translate = useTranslate();


  useEffect(() => {
    if (localStorage.getItem("rememberMe") && localStorage.getItem("token")) {
      const remember: boolean = JSON.parse(localStorage.getItem("rememberMe"));
      if (remember) redirect("/");
    }
  }, [redirect]);

  const validate = (values) => {
    const errors = { email: undefined, password: undefined };

    if (!values.email) {
        errors.email = translate("login.email_required");
    }
    if (!values.password) {
        errors.password = translate("login.password_required");
    }
    return errors;
  };

  const submit = async (event) => {
    event.preventDefault();

    const data = {
      email: event.target.email.value,
      password: event.target.password.value,
    };

    // validate inputs
    const errors = validate(data);

    if (errors.email || errors.password) {
      notify("ra.auth.sign_in_error", { type: "warning" });
      return;
    }

    setLoading(true);
    try {
      await login(data);
    } catch (e) {
      notify("ra.auth.sign_in_error", {
        type: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleRememberMe = (event: React.ChangeEvent<{}>, checked: boolean) => {
    setRememberMe(checked);
    localStorage.setItem("rememberMe", JSON.stringify(checked));
  };

  return (
    <form onSubmit={submit}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1em",
        }}
      >
        <TextField
          autoFocus
          id='email'
          name='email'
          type='email'
          label={translate("login.email")}
          disabled={loading}
          variant='outlined'
          fullWidth
        />
        <TextField
          type='password'
          id='password'
          name='password'
          label={translate("login.password")}
          disabled={loading}
          autoComplete='current-password'
          variant='outlined'
          fullWidth
        />
        <FormControlLabel
          disabled={loading}
          control={<Checkbox color='primary' />}
          label={translate("login.remember_me")}
          onChange={handleRememberMe}
          checked={rememberMe}
        />

        <Button
          variant='contained'
          type='submit'
          color='primary'
          disabled={loading}
          fullWidth
        >
          {loading && <CircularProgress size={18} thickness={2} />}
            {translate("login.sign_in")}
        </Button>

        {/* Forgot password */}
        <Link
          href='https://www.univrdev.com/forgot-password'
          target='_blank'
          align='center'
          style={{ color: "#282828 !important" }}
        >
            {translate("login.forgot_password")}
        </Link>

        {/*<Divider flexItem sx={{ my: 3 }}>*/}
        {/*  OU*/}
        {/*</Divider>*/}

        {/*<GoogleButton />*/}
      </Box>
    </form>
  );
};

CustomLoginForm.propTypes = {
  redirectTo: PropTypes.string,
};

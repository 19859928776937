import {Button, Container, IconButton, MenuItem, Paper, Select, Stack, TextField, Typography,} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import {Title, useNotify, usePermissions, useTranslate} from "react-admin";
import {CustomTitle} from "../Layout/CustomTitle";
import EditIcon from "@mui/icons-material/Edit";
import {useEffect, useState} from "react";
import dataProvider from "../../data-providers/data-provider";
import {APIURL} from "../../global/global";
import SkeletonProfile from "../Loading/SkeletonProfile";

const useStyles = makeStyles({
  itemTitle: {
    color: "#919191",
    width: "10em",
  },
  itemValue: {
    flex: "1",
  },
  chip: {
    marginRight: "1em",
  },
  save: {
    width: "fit-content",
    marginTop: "4em",
  },
  list: {
    marginTop: "1em",
  },
});

interface ItemProps {
  value: string;
  title: string;
  onSave: (any) => void;
}

interface SelectItemProps extends ItemProps {
  choices: { id: number; name: string }[];
}

const TextItem = ({ title, value, onSave }: ItemProps) => {
  const classes = useStyles();
  const [edit, setEdit] = useState(false);
  const [state, setState] = useState<string>(value);

  const onChange = (event) => {
    setState(event.target.value);
    onSave({ value: event.target.value });
  };

  return (
    <Stack
      component={Paper}
      direction={"row"}
      alignItems={"center"}
      p={2}
      height={"5em"}
      gap={2}
    >
      <Typography className={classes.itemTitle}>{title}</Typography>

      {/* Field */}
      {!edit && <Typography className={classes.itemValue}>{state}</Typography>}

      {/* Input */}
      {edit && (
        <TextField
          className={classes.itemValue}
          sx={{ m: 0 }}
          defaultValue={value}
          variant="outlined"
          onChange={onChange}
          size={"small"}
        />
      )}

      <IconButton aria-label="edit" onClick={() => setEdit(!edit)} size="large">
        <EditIcon />
      </IconButton>
    </Stack>
  );
};

const SelectItem = ({ title, value, choices, onSave }: SelectItemProps) => {
  const classes = useStyles();
  const [edit, setEdit] = useState(false);
  const [state, setState] = useState<any>(value);

  const onChange = (event) => {
    setState(event.target.value);
    onSave({ value: event.target.value });
  };

  return (
    <Stack
      component={Paper}
      direction={"row"}
      alignItems={"center"}
      p={2}
      height={"5em"}
      gap={2}
    >
      <Typography className={classes.itemTitle}>{title}</Typography>

      {/* Field */}
      {!edit && (
        <Typography className={classes.itemValue}>
          {choices.find((choice) => choice.id === state)?.name}
        </Typography>
      )}

      {/* Input */}
      {edit && (
        <Select
          className={classes.itemValue}
          value={state}
          defaultValue={state}
          onChange={onChange}
        >
          {choices.map((choice) => (
            <MenuItem value={choice.id}>{choice.name}</MenuItem>
          ))}
        </Select>
      )}

      <IconButton aria-label="edit" onClick={() => setEdit(!edit)} size="large">
        <EditIcon />
      </IconButton>
    </Stack>
  );
};

export const Profile = () => {
  /**
   * LocalStorage
   */
  const {
    id: userId,
    first_name,
    last_name,
    email,
  } = JSON.parse(localStorage.getItem("me"));
  const organization = JSON.parse(localStorage.getItem("organization"));

  /**
   * Hooks => use localStorage data as fuel for userState
   */
  const classes = useStyles();
  const notify = useNotify();
  const { permissions } = usePermissions();
  const [userState, setUserState] = useState({
    id: userId,
    first_name,
    last_name,
    organization_id: organization.id,
    email,
    // roles: [],
  });
  const [organizations, setOrganizations] = useState<
    { id: number; name: string }[]
  >([]);
  const [loading, setLoading] = useState(true);
    const translate = useTranslate();

  // get data from API
  useEffect(() => {
    // get organizations
    if (organizations.length === 0 && permissions)
      dataProvider
        .getList("organizations")
        .then(({ data }) => {
          let result = data.map((o) => ({ id: o.id, name: o.name }));

          if (!permissions.includes("admin"))
            result = data.filter((o) => o.id === organization.id);

          setOrganizations(result);
        })
        .catch((err) => console.error(err));
  }, [organization.id, organizations.length, permissions, userState]);

  const onSave = () => {
    const { access_token } = JSON.parse(localStorage.getItem("token"));

    const request = new Request(`${APIURL}/users/${userId}`, {
      method: "POST",
      body: JSON.stringify(userState),
      headers: new Headers({
        "Content-Type": "application/json",
        locale: "fr",
        Authorization: `Bearer ${access_token}`,
      }),
    });

    fetch(request)
      .then((res) => res.json())
      .then(
        ({ id, email, first_name, last_name, organization_id, roles }) => {
          localStorage.setItem("roles", JSON.stringify(roles));
          localStorage.setItem(
            "me",
            JSON.stringify({ id, email, first_name, last_name })
          );

          dataProvider
            .getOne("organizations", { id: organization_id })
            .then(({ data }) => {
              localStorage.setItem(
                "organization",
                JSON.stringify({ id: data.id, name: data.name })
              );

              notify("Profil mis à jour avec succès", { type: "info" });
            });
        },

        (error) => notify("ra.notification.http_error", { type: "error" })
      );
  };

  useEffect(() => {
    if (organizations.length === 0 || !permissions) setLoading(true);
    else setLoading(false);
  }, [organizations.length, permissions]);

  return (
    <Container maxWidth={"md"}>
      <Stack alignItems={loading ? "" : "flex-end"} gap={4} p={2}>
        {!loading ? (
          <>
            <Title title={<CustomTitle titles={["Profil"]} />} />

            <Typography
              fontWeight={"bold"}
              variant={"h5"}
              textAlign={"center"}
              width={"100%"}
            >
                {translate("profile.title")}
            </Typography>

            <Stack gap={2} width={"100%"}>
              <TextItem
                  title={translate("profile.first_name")}
                value={userState.first_name}
                onSave={({ value }) =>
                  setUserState({ ...userState, first_name: value })
                }
              />

              <TextItem
                  title={translate("profile.last_name")}
                value={userState.last_name}
                onSave={({ value }) =>
                  setUserState({ ...userState, last_name: value })
                }
              />

              <TextItem
                  title={translate("profile.email")}
                value={userState.email}
                onSave={({ value }) =>
                  setUserState({ ...userState, email: value })
                }
              />

              <SelectItem
                  title={translate("profile.organization")}
                value={userState.organization_id}
                choices={organizations}
                onSave={({ value }) =>
                  setUserState({ ...userState, organization_id: value })
                }
              />
            </Stack>

            <Button
              className={classes.save}
              variant="contained"
              color="primary"
              onClick={onSave}
            >
                {translate("profile.save")}
            </Button>
          </>
        ) : (
          <SkeletonProfile />
        )}
      </Stack>
    </Container>
  );
};

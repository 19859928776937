import {Button} from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import {theme} from "../../../global/styles";
import authProvider from "../../../data-providers/auth-provider";
import {useTranslate} from "react-admin";

const LogoutButton = () => {
    const translate = useTranslate();

    const logout = async () => {
        await authProvider.logout();
        window.location.href = "/";
    };

    return (
        <Button
            onClick={logout}
            startIcon={<LogoutIcon/>}
            variant={"text"}
            sx={{
                width: "100%",
                justifyContent: "flex-start",
                paddingLeft: "24px",
                borderRadius: 0,
                color: "rgba(0, 0, 0, 0.6)",
                "&:hover": {
                    borderRadius: 0,
                    paddingLeft: "24px",
                    background: "rgba(0, 0, 0, 0.04)",
                    color: theme.palette.primary.main,
                },
                "&:focus": {outline: "none"},
                "& .MuiButton-startIcon": {
                    marginRight: "16px",
                }
            }}
        >
            {translate('login.logout')}
        </Button>
    );
};

export default LogoutButton;
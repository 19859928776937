import {Edit} from "react-admin";
import OrganizationForm from "./OrganizationForm";
import {uploadFile} from "../../services/uploader-service";

export const organizationFormTransform = async (data: any) => {
    if (data.default_experience_image?.rawFile) {
        data.default_experience_image = await uploadFile(data.default_experience_image.rawFile, () => {
        })
    }
    return data;
}

const OrganizationEdit = (props) => {
    return (
        <Edit transform={organizationFormTransform}>
            <OrganizationForm {...props} />
        </Edit>
    );
};

export default OrganizationEdit;

import {Button, Paper, Stack, Typography} from "@mui/material";
import {Link} from "react-router-dom";
import React from "react";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

interface ItemProps {
    label: string;
    value?: number;
    comingLater?: boolean;
    more?: string;
}

const Item: React.FC<ItemProps> = ({label, value, comingLater, more}) => {
    const defaultSx = {};
    const comingLaterSx = {
        color: 'gray',
        fontSize: 12,
        backgroundColor: 'rgba(0, 0, 0, 0.05)',
    };

    return (
        <Stack
            component={Paper}
            variant="outlined"
            p={1}
            textAlign="center"
            sx={comingLater ? comingLaterSx : defaultSx}
            height={"100%"}
        >
            <Typography variant="body2" color="gray" alignItems={'baseline'}>
                {label}
            </Typography>
            {comingLater ? (
                <Typography variant="body1" fontWeight="bold" fontSize={24}>
                    Bientôt disponible 🤗
                </Typography>
            ) : (
                <Typography variant="body1" fontWeight="bold" py={2} fontSize={24} alignItems={'center'}>
                    {value ?? 0}
                </Typography>
            )}
            <Button
                component={Link}
                to={more}
                variant='text'
                color='primary'
                endIcon={<ChevronRightIcon/>}
                size={"small"}
                sx={{alignSelf: 'flex-end'}}
            >
                Voir plus
            </Button>
        </Stack>
    );
};


Item.defaultProps = {
    comingLater: false
}

export default Item;
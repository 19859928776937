import {useEffect} from "react";
import {Login, useTranslate} from "react-admin";
// import backgroundImage from "../../assets/images/univr-background.png";
import {APIURL} from "../../global/global";
import {CustomLoginForm} from "./CustomLoginForm";
import {Stack, Typography} from "@mui/material";

export function Welcome({userName}: { userName?: string }) {
    const translate = useTranslate();

    return <Typography
        align={"center"} variant={"h6"} fontWeight={"bold"} my={2}
    >
        {translate("welcome_to")} <span style={{
        backgroundImage: `linear-gradient(to right, #0C9AF2, #79D7ED)`,
        backgroundClip: "text",
        WebkitBackgroundClip: "text",
        WebkitTextFillColor: "transparent",
        color: "transparent",
    }}>UniVR Manager</span> {userName ? `, ${userName}` : null} 👋
    </Typography>;
}

const CustomLogin = () => {
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);

    if (!urlParams.has("token")) return;

    (async () => {
      const token = urlParams.get("token");

      const info = await fetch(`${APIURL}/auth/info`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
        // Check return value of res.
          .then((res) => res.json())
        .catch((err) => console.log(err));

      const { access_token, roles, user, organization } = info;

      localStorage.setItem("token", JSON.stringify(access_token));
      localStorage.setItem("roles", JSON.stringify(roles));
      localStorage.setItem("me", JSON.stringify(user));
      localStorage.setItem("organization", JSON.stringify(organization));

      window.location.href = "/";
    })();
  }, []);

    return (
        <Login
            sx={{
                // card
                "& .MuiCard-root": {
                    width: "100%",
                    maxWidth: "32em",
                },
                backgroundImage: "url(background.svg)",
                '& .RaLogin-avatar': {
                    display: "none"
                },
            }}
        >
            <Stack p={6} gap={4}>
                <Stack gap={2}>
                    <Stack alignItems={'center'} width={'100%'}>
                        <img src={"univr-black.svg"} alt="Univr SVG" style={{color: "black", width: "5em"}}/>
                    </Stack>
                    <Welcome/>
                </Stack>
                <CustomLoginForm/>
            </Stack>
        </Login>
    );
};

export default CustomLogin;

import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  IconButton,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useEffect, useState } from "react";
import dataProvider from "../../data-providers/data-provider";
import { formatDate } from "../../global/global";
import { ResourceType } from "../../models/resource";
import { IRole } from "../../models/role";
import { CustomTitle } from "./CustomTitle";
import { theme } from "../../global/styles";
import { Link } from "react-router-dom";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import FlashingDot from "../Notification/FlashingDot";
import { useTranslate } from "react-admin";

/**
 * Styles
 */

const useStyles = makeStyles({
  card: {
    height: "100%",
    width: "100%",
    borderRadius: "16px",
    boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
    backdropFilter: "blur(5px)",
    "-webkit-backdrop-filter": "blur(5px)",
  },
  header: {
    background: theme.palette.primary.main,
    color: "white",
  },
  container: {
    display: "flex",
    alignItems: "center",
    gap: "6px",
  },
  mainInfo: {
    flex: "1",
    color: theme.palette.primary.main,
    fontWeight: 600,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    marginRight: "1em",
  },
});

/**
 * Interfaces
 */

interface BaseContainerProps {
  data: string[];
  more?: string;
  notification?: boolean;
}

interface DashboardCardProps {
  resource: ResourceType;
  amount?: number;
  organizationId?: number;
  roles?: IRole[];
  more?: string;
}

/**
 * Components
 */

const BaseContainer = ({ data, more, notification }: BaseContainerProps) => {
  const classes = useStyles();
  const translate = useTranslate();

  return (
    <Stack direction={"row"} px={1} alignItems={"center"} gap={1}>
      {data.map((d, index, arr) => (
        <Tooltip title={d} key={index}>
          <Typography
            className={index < arr.length - 1 ? classes.mainInfo : null}
            key={d}
          >
            {d}
          </Typography>
        </Tooltip>
      ))}
      {notification && <FlashingDot />}
      {more && (
        <Tooltip title={translate("misc.see_more")}>
          <IconButton component={Link} to={more} size={"small"}>
            <ChevronRightIcon />
          </IconButton>
        </Tooltip>
      )}
    </Stack>
  );
};

export const DashboardCard = ({
  resource,
  amount,
  organizationId,
  roles,
  more,
}: DashboardCardProps) => {
  const classes = useStyles();
  const [resourceData, setResourceData] = useState<any[] | null>(null);
  const [total, setTotal] = useState<number | null>(null);
  const translate = useTranslate();

  useEffect(() => {
    dataProvider
      .getList(`${resource}/last`, {
        amount: amount ? amount : 5,
        organization_id: organizationId,
      })
      .then((res) => {
        setResourceData(
          res.data.map((d) => ({ ...d, updated_at: formatDate(d.updated_at) }))
        );
        setTotal(res?.total);
      })
      .catch((err) => console.error(err));
  }, [amount, organizationId, resource]);

  const getContainer = (rd) => {
    const data = [];

    switch (resource) {
      case "experiences":
        data.push(rd.name, rd.updated_at);
        break;
      case "organizations":
        data.push(rd.name, rd.updated_at);
        break;
      case "users":
        const roleName = roles.find((r) => r.id === rd.roles[0])?.name;
        data.push(
          `${rd.first_name} ${rd.last_name}`,
          rd.organization_name,
          rd.updated_at
        );
        break;
      case "licenses":
        data.push(
          rd.organization?.name,
          rd?.experience_group?.name ?? rd?.experience?.name,
          rd.updated_at
        );
        break;
      case "service-licenses":
        data.push(
          rd.organization?.name,
          translate(`service-licenses.types.${rd.type}`),
          rd.updated_at
        );
        break;
    }

    const getMore = () => {
      switch (resource) {
        case "experiences":
          return `/experiences/${rd.id}`;
        case "organizations":
          return `/organizations/${rd.id}`;
        case "users":
          return `/organizations/${rd.organization?.id}/users/${rd.id}`;
        case "licenses":
          return `/organizations/${rd.organization?.id}/licenses/${rd.id}`;
        case "service-licenses":
          return `/organizations/${rd.organization?.id}/service-licenses/${rd.id}`;
      }
    };

    return (
      <BaseContainer
        key={rd.id}
        data={data}
        more={getMore()}
        notification={rd?.validity === "expired"}
      />
    );
  };

  const getTitle = () => {
    switch (resource) {
      case "experiences":
        return [
          translate("resources.experiences"),
          translate("misc.last_updates"),
        ];
      case "organizations":
        return [
          translate("resources.organizations"),
          translate("misc.last_updates"),
        ];
      case "users":
        return [translate("resources.users"), translate("misc.last_updates")];
      case "licenses":
        return [
          translate("resources.licenses"),
          translate("misc.last_updates"),
        ];
      case "service-licenses":
        return [
          translate("resources.service-licenses"),
          translate("misc.last_updates"),
        ];
      default:
        return [];
    }
  };
  return (
    <div>
      {resourceData ? (
        <Card className={classes.card}>
          <CardHeader
            title={<CustomTitle titles={getTitle()} />}
            className={classes.header}
            sx={{
              // MuiCardHeader-content => flex and justify-content
              "& .MuiCardHeader-content": {
                display: "flex",
                justifyContent: "center",
              },
            }}
          />

          <CardContent>
            <Stack gap={3}>
              {resourceData.length > 0 &&
                resourceData.map((rd, index, arr) => getContainer(rd))}

              {resourceData.length === 0 && (
                <Typography textAlign={"center"}>
                  {translate("misc.no_data")}
                </Typography>
              )}
            </Stack>
          </CardContent>
          <CardActions
            sx={{
              display: "flex",
              alignItems: "center",
              px: 3,
            }}
          >
            {total !== null && (
              <Typography
                variant={"body2"}
                fontWeight={"bold"}
                color={"GrayText"}
                flex={1}
              >
                {translate("misc.total")} |{" "}
                <span style={{ color: "black" }}>{total}</span>
              </Typography>
            )}
            {more && (
              <Button
                color={"primary"}
                size={"small"}
                endIcon={<ChevronRightIcon />}
                component={Link}
                to={more}
              >
                {translate("misc.see_more")}
              </Button>
            )}
          </CardActions>
        </Card>
      ) : (
        <Skeleton variant='rectangular' width='100%' height='100%' />
      )}
    </div>
  );
};

import React from "react";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";
import { useRecordContext, useTranslate } from "react-admin";

const ServiceLicenseListButton = () => {
  const record = useRecordContext();
  const translate = useTranslate();

  return (
    <Button
      component={Link}
      to={`/organizations/${record.id}/service-licenses`}
      variant='text'
      color='primary'
    >
      {translate("resources.service-licenses")}
    </Button>
  );
};

export default ServiceLicenseListButton;

import {
  AutocompleteArrayInput,
  DateInput,
  ImageField,
  ImageInput,
  ReferenceArrayInput,
  SimpleForm,
  TextInput,
  usePermissions,
  useRecordContext,
  useRedirect,
  useTranslate,
} from "react-admin";
import { hasAccess } from "../../global/permissions";
import moment from "moment";
import { Container, Grid, Stack, Typography } from "@mui/material";
import Item from "../Layout/Items/Item";
import SkeletonForm from "../Loading/SkeletonForm";
import CustomSimpleToolbar from "../Layout/CustomSimpleToolbar";
import { DashboardLastNews } from "../Dashboard/DashboardLastNews";
import { NameField } from "../Experiences/Form/ExperienceForm";
import React from "react";
import AutoCreateTag from "../Tags/AutoCreateTag";

const AdditionalInformation = ({ record }) => (
  <Stack gap={2} width={"100%"}>
    <Typography variant='subtitle1' fontWeight='bold'>
      Informations supplémentaires
    </Typography>
    <Grid container spacing={2}>
      {[
        {
          label: "Utilisateurs",
          value: record?.user_count,
          more: `/organizations/${record?.id}/users`,
        },
        {
          label: "Expériences",
          value: record?.owned_experience_count,
          more: `/experiences`,
        },
        {
          label: "Licences",
          value: record?.licensed_experience_count,
          more: `/organizations/${record?.id}/licenses`,
        },
      ].map((item, index) => (
        <Grid item sm={12} md={6} lg={4} key={index}>
          <Item label={item.label} value={item.value} more={item.more} />
        </Grid>
      ))}
    </Grid>
  </Stack>
);

const OrganizationForm = (props) => {
  const redirect = useRedirect();
  const { permissions } = usePermissions(props);
  const record = useRecordContext(props);
  const translate = useTranslate();

  /**
   * Validate data.
   * @param values
   */
  const validateForm = (values) => {
    const errors = {};

    if (!values.name) {
      errors["name"] = "Ce champ est obligatoire";
    }

    if (!values.number_of_workstations) {
      errors["number_of_workstations"] = "Ce champ est obligatoire";
    }

    if (!values.contract_started_at) {
      errors["contract_started_at"] = "Ce champ est obligatoire";
    }

    if (!values.contract_ended_at) {
      errors["contract_ended_at"] = "Ce champ est obligatoire";
    }

    return errors;
  };

  if (!permissions)
    return (
      <div>
        <SkeletonForm />
      </div>
    );

  if (!hasAccess({ name: "organizations", crudType: "edit" }, permissions))
    redirect("/");

  return (
    <Container maxWidth={"lg"}>
      <SimpleForm validate={validateForm} toolbar={<CustomSimpleToolbar />}>
        <Stack gap={2}>
          <TextInput
            source='name'
            label={translate("organizations.name")}
            sx={{ width: "20em", alignSelf: "center" }}
          />
          <TextInput
            source='number_of_workstations'
            label='Nombre de postes'
            defaultValue={1}
            sx={{ display: "none" }}
          />
          <DateInput
            source='contract_started_at'
            label='Date de début de contrat'
            defaultValue={moment().format("YYYY-MM-DD")}
            sx={{ display: "none" }}
          />
          <DateInput
            source='contract_ended_at'
            label='Date de fin de contrat'
            defaultValue={moment().format("YYYY-MM-DD")}
            sx={{ display: "none" }}
          />

          <Stack>
            <Typography variant={"subtitle1"}>
              {translate("misc.options")}
            </Typography>
            <ReferenceArrayInput
              source={"category_ids"}
              reference={"tags"}
              perPage={1000}
              sort={{ field: "name", order: "ASC" }}
              filter={{ type: "organizationCategory" }}
              fullWidth
              optionText={<NameField />}
              optionValue={"name"}
            >
              <AutocompleteArrayInput
                label={translate("tags.categories.index")}
                sx={{ width: "fit-content" }}
                multiple
                inputText={(option) => option?.name}
                matchSuggestion={(filterValue, suggestion) =>
                  suggestion?.name
                    .toLowerCase()
                    .includes(filterValue?.toLowerCase())
                }
                noOptionsText={translate("misc.no_data")}
                disableCloseOnSelect
                blurOnSelect={false}
                create={<AutoCreateTag type={"organizationCategory"} />}
                allowCreate
                createText={translate("tags.categories.create")}
              />
            </ReferenceArrayInput>

            <TextInput
              source={"email_domain"}
              label={translate("misc.email_domain")}
              placeholder={"example.com"}
              size={"medium"}
              sx={{ width: "fit-content", minWidth: "20em" }}
            />

            <ImageInput
              source='default_experience_image'
              label={translate("organizations.experiences.images.default")}
              accept={{ "image/*": [] }}
              helperText={translate("misc.upload_image_helper")}
              sx={{ width: "fit-content" }}
            >
              <>
                <ImageField source='public_display_link' title='id' />
                <ImageField source='src' title='id' />
              </>
            </ImageInput>
          </Stack>

          {record && (
            <Stack gap={2}>
              <Typography variant={"subtitle1"}>
                {translate("misc.general_informations")}
              </Typography>
              <DashboardLastNews
                permissions={permissions}
                organizationId={record.id}
              />
            </Stack>
          )}
        </Stack>
      </SimpleForm>
    </Container>
  );
};

export default OrganizationForm;

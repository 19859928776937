export interface IAPIRole {
    id: number;
    name: RoleID;
}

export interface IRole {
    id: RoleID;
    name: string;
}

export type RoleID = "admin" | "adminOrga" | "normal" | "contentCreator";

export const roleNames: { [key in RoleID]: string } = {
    admin: "Administrateur",
    adminOrga: "Administrateur d'organisation",
    normal: "Utilisateur",
    contentCreator: "Créateur de contenu",
}
import {
  Button,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import {
  DeleteWithConfirmButton,
  Form,
  SaveButton,
  useRecordContext,
  useTranslate,
} from "react-admin";
import { theme } from "../../../global/styles";
import SendIcon from "@mui/icons-material/Send";
import { useWatch } from "react-hook-form";

interface StepperManagerProps {
  steps: string[];
  componentsSteps: React.ReactNode[];
  consent: boolean;
}

export const managerUseStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    gap: "2em",
    padding: "5em",
  },
  stepper: {
    padding: "2em 15em 0",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "space-between",
    padding: "0 15em 2em",
  },
  backButtonDisplayed: {
    // marginRight: "2em",
  },
  backButtonHidden: {
    opacity: 0,
  },
  fileFieldManager: {
    "& a": {
      pointerEvents: "none",
      textDecoration: "none",
      color: "inherit",
    },
  },
  labelManager: {
    fontSize: "0.75em",
    fontWeight: "400",
    color: "rgba(0, 0 ,0 ,0.6)",
    marginBottom: "-1em",
  },
  switchManagerTextShared: {
    // margin: 0,
    // paddingBlock: '0.5em',
    color: theme.palette.primary.main,
    textDecoration: "underline",
  },
  switchManagerTextNotShared: {
    // margin: 0,
    // paddingBlock: '0.5em',
  },
  switchManagerContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignContent: "center",
    width: "35em",
  },
});

interface LabelManagerProps {
  label: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
}

export const LabelManager: React.FC<LabelManagerProps> = ({
  label,
  style = {},
  children,
}) => {
  return (
    <Stack>
      <Typography
        sx={{
          fontSize: "0.75em",
          color: "rgba(0, 0, 0, 0.6)",
        }}
        variant={"body1"}
      >
        {label}*
      </Typography>
      {children}
    </Stack>
  );
};

const NextButton: React.FC<any> = ({ handleNext, activeStep }) => {
  const watch = useWatch();
  const translate = useTranslate();

  const isNextBtnDisabled = () => {
    switch (activeStep) {
      case 0:
        return isStepGeneralInvalid();
      case 1:
        return isStepTechnicalInvalid();
      default:
        return false;
    }
  };

  const isStepGeneralInvalid = () => {
    return !watch?.name || watch?.name.length > 70 || !watch?.description;
  };

  const isBuildOrVideoInvalid = () => {
    return !watch?.build;
  };

  const isBrowserInvalid = () => {
    const urlPattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name and extension
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?" + // port
        "(\\/[-a-z\\d%_.~+]*)*" + // path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    ); // fragment locator
    return !watch?.url || !urlPattern.test(watch.url);
  };

  const isOculusInvalid = () => {
    const exePattern = new RegExp(".*\\.(exe|apk)$");
    return !watch?.path_to_exe || exePattern.test(watch.path_to_exe);
  };

  const areCommonFieldsInvalid = () => {
    return (
      !watch?.version_description ||
      !watch?.editeur_ids ||
      watch?.editeur_ids?.length === 0 ||
      !watch?.compatible_headset_ids ||
      watch?.compatible_headset_ids?.length === 0
    );
  };

  const isExeNameInvalid = () => {
    const exePattern = new RegExp(".*\\.(exe|apk|msi)$");
    return !watch?.executable_name || !exePattern.test(watch.executable_name);
  };

  const isStepTechnicalInvalid = () => {
    if (!watch?.type) return true;

    let check = false;

    if (watch?.type === "build" || watch.type === "video") {
      check = check || isBuildOrVideoInvalid();
    }
    if (watch?.type === "browser") {
      check = check || isBrowserInvalid();
    }
    if (watch?.type === "build" || watch?.type === "oculus") {
      check = check || isExeNameInvalid();
    }
    if (watch?.type === "package") {
      check = check || !watch?.package_name;
    }
    if (watch?.type === "installer") {
      check =
        check ||
        isBuildOrVideoInvalid() ||
        !watch?.package_name ||
        isExeNameInvalid();
    }

    return check || areCommonFieldsInvalid();
  };

  return (
    <Button
      variant={"contained"}
      onClick={handleNext}
      disabled={isNextBtnDisabled()}
    >
      {translate("misc.next")}
    </Button>
  );
};

const StepperManager: React.FC<StepperManagerProps> = ({
  steps,
  componentsSteps,
  consent = true,
}) => {
  const classes = managerUseStyles();
  const [activeStep, setActiveStep] = React.useState<number>(0);
  const record = useRecordContext();
  const hasDelete = !!record;
  const translate = useTranslate();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const defaultValues = {
    version_description: record
      ? translate("experiences.new_version")
      : translate("experiences.first_version"),
    type: null,
  };

  return (
    <Form mode='onBlur' reValidateMode='onBlur' defaultValues={defaultValues}>
      <Stepper activeStep={activeStep} className={classes.stepper}>
        {steps.map((label: string, index: number) => (
          <Step key={label} completed={activeStep > index}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div>
        {activeStep === steps.length ? (
          <div>
            <Typography>All steps completed</Typography>
            <Button onClick={handleReset}>Reset</Button>
          </div>
        ) : (
          <div>
            <div>{componentsSteps[activeStep]}</div>
            <Stack
              direction={"row"}
              px={10}
              pb={4}
              width={"100%"}
              alignItems={"center"}
              justifyContent={"flex-end"}
            >
              {hasDelete && (
                <DeleteWithConfirmButton
                  size={"medium"}
                  variant={"contained"}
                  icon={null}
                  color={"error"}
                  confirmContent={
                    <Typography variant={"body1"}>
                      {translate("experiences.delete_confirm")}
                    </Typography>
                  }
                  confirmTitle={`Suppression de l'expérience ${record.name}`}
                  label={translate("ra.action.delete")}
                  record={record}
                  redirect={"/experiences"}
                />
              )}
              <Stack
                direction={"row"}
                gap={2}
                flex={1}
                justifyContent={"flex-end"}
              >
                <Button
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  className={activeStep === 0 ? classes.backButtonHidden : null}
                  variant={"contained"}
                >
                  {translate("misc.back")}
                </Button>
                {activeStep === steps.length - 1 ? (
                  <SaveButton
                    variant={"contained"}
                    disabled={!consent}
                    label={translate("misc.publish")}
                    icon={<SendIcon />}
                    alwaysEnable={consent}
                  />
                ) : (
                  <NextButton handleNext={handleNext} activeStep={activeStep} />
                )}
              </Stack>
            </Stack>
          </div>
        )}
      </div>
    </Form>
  );
};

export default StepperManager;

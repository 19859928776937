import {
  Datagrid,
  DateField,
  EditButton,
  List,
  ReferenceField,
  TextField,
  useListContext,
  usePermissions,
  useRedirect,
  useTranslate,
} from "react-admin";
import { useEffect, useState } from "react";
import { hasAccess } from "../../global/permissions";
import LicenseListButton from "../Licenses/Buttons/LicenseListButton";
import CustomActionBar from "../Layout/CustomActionBar";
import SkeletonList from "../Loading/SkeletonList";
import DefaultFilter from "../Layout/Filters/DefaultFilter";
import ListLayout from "../Layout/ListLayout";

const BulkActionButtons = ({ onSelectedIdsChange }) => {
  const { selectedIds } = useListContext();

  useEffect(() => {
    onSelectedIdsChange(selectedIds);
  }, [selectedIds, onSelectedIdsChange]);

  return <></>;
};

const ExperienceList = ({
  simplifiedView,
  onSelectedIdsChange,
  standaloneOnly,
}: {
  simplifiedView?: boolean;
  onSelectedIdsChange?: any;
  standaloneOnly?: boolean;
}) => {
  const organization = JSON.parse(localStorage.getItem("organization"));
  const redirect = useRedirect();
  const { permissions } = usePermissions();
  const [loading, setLoading] = useState(true);
  const translate = useTranslate();

  useEffect(() => {
    if (!organization || !permissions) setLoading(true);
    else setLoading(false);
  }, [organization, permissions]);

  if (!hasAccess({ name: "experiences", crudType: "list" }, permissions))
    redirect("/");

  let filterValues = !permissions.includes("admin")
    ? { organization_id: organization.id, standalone_only: standaloneOnly }
    : { standalone_only: standaloneOnly };

  const showLicenseButton =
    hasAccess({ name: "licenses", crudType: "list" }, permissions) &&
    !simplifiedView;

  const list = !loading ? (
    <List
      resource={"experiences"}
      exporter={false}
      perPage={50}
      filters={<DefaultFilter />}
      sort={{ field: "updated_at", order: "DESC" }}
      filter={filterValues}
      actions={
        simplifiedView ? null : (
          <CustomActionBar
            createButtonProps={{ label: translate("experiences.create") }}
          />
        )
      }
      disableSyncWithLocation={simplifiedView}
    >
      <Datagrid
        bulkActionButtons={
          simplifiedView ? (
            <BulkActionButtons onSelectedIdsChange={onSelectedIdsChange} />
          ) : (
            false
          )
        }
        rowClick={simplifiedView ? false : "edit"}
      >
        <TextField source='id' label={translate("misc.id")} />
        <TextField source='name' label={translate("misc.name")} />
        {!simplifiedView && (
          <ReferenceField
            label={translate("organizations.singular_name")}
            source='organization_id'
            reference='organizations'
          >
            <TextField source='name' />
          </ReferenceField>
        )}
        <DateField
          source='updated_at'
          label={translate("misc.last_update")}
          showTime
        />
        {showLicenseButton && (
          <LicenseListButton parentResource='experiences' />
        )}
        {!simplifiedView && <EditButton variant='contained' icon={null} />}
      </Datagrid>
    </List>
  ) : (
    <SkeletonList />
  );

  if (simplifiedView) return list;

  return (
    <ListLayout title={translate("resources.experiences")}>{list}</ListLayout>
  );
};

ExperienceList.defaultProps = {
  simplifiedView: false,
  standaloneOnly: false,
};

export default ExperienceList;

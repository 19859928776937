import { Skeleton } from "@mui/material";

const SkeletonProfile = () => {
  return (
    <div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Skeleton variant="text" width="50%" height={60} />
      </div>
      <Skeleton variant="text" width="100%" height={100} />
      <Skeleton variant="text" width="100%" height={100} />
      <Skeleton variant="text" width="100%" height={100} />
      <Skeleton variant="text" width="100%" height={100} />
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "2em",
        }}
      >
        <Skeleton variant="text" width="20%" height={60} />
      </div>
    </div>
  );
};

export default SkeletonProfile;

import {
  Datagrid,
  EditButton,
  FunctionField,
  List,
  TextField,
  usePermissions,
  useRedirect,
  useTranslate,
} from "react-admin";
import { hasAccess } from "../../global/permissions";
import { CustomPagination } from "../Layout/CustomPagination";
import LicenseListButton from "../Licenses/Buttons/LicenseListButton";
import UserListButton from "../Users/Buttons/UserListButton";
import React, { useEffect, useState } from "react";
import CustomActionBar from "../Layout/CustomActionBar";
import SkeletonList from "../Loading/SkeletonList";
import DefaultFilter from "../Layout/Filters/DefaultFilter";
import ListLayout from "../Layout/ListLayout";
import StatusIcon from "../Layout/Icons/StatusIcon";
import { ServiceLicenseStatus } from "../../models/service-license";
import ServiceLicenseListButton from "../ServiceLicenses/Buttons/ServiceLicenseListButton";

const OrganizationList = () => {
  const translate = useTranslate();
  const organization = JSON.parse(localStorage.getItem("organization"));
  const redirect = useRedirect();
  const { permissions } = usePermissions();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (permissions.includes("adminOrga")) {
      redirect(`/organizations/${organization.id}/edit`);
    }
  }, []);

  useEffect(() => {
    if (!organization || !permissions) setLoading(true);
    else setLoading(false);
  }, [organization, permissions]);

  if (!hasAccess({ name: "organizations", crudType: "list" }, permissions))
    redirect("/");

  return (
    <ListLayout title={translate("resources.organizations")}>
      {!loading ? (
        <List
          exporter={false}
          perPage={50}
          pagination={<CustomPagination />}
          filters={
            <DefaultFilter
              hasStatusFilter
              statusFilterLabel={translate(
                "organizations.service-license-status"
              )}
              statusFilterName={"service_license_status"}
            />
          }
          filterDefaultValues={
            !permissions.includes("admin") ? { name: organization.name } : null
          }
          sort={{ field: "updated_at", order: "DESC" }}
          actions={
            <CustomActionBar
              createButtonProps={{ label: "organizations.create" }}
            />
          }
        >
          <Datagrid bulkActionButtons={false}>
            <TextField source='name' label={translate("misc.name")} />
            <LicenseListButton />
            <ServiceLicenseListButton />
            <UserListButton />
            <FunctionField
              source='status'
              label={translate("organizations.service-license-status")}
              render={(record: {
                service_license_status: ServiceLicenseStatus;
              }) => <StatusIcon status={record.service_license_status} />}
              sortable={false}
            />
            <EditButton variant='contained' translate={"yes"} icon={null} />
          </Datagrid>
        </List>
      ) : (
        <SkeletonList />
      )}
    </ListLayout>
  );
};

export default OrganizationList;

import {Chip} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import {useRecordContext} from "ra-core";
import {roleNames} from "../../../models/role";

const useStyles = makeStyles({
  chip: {
    margin: ".7em 1em .7em 0em",
  },
});

export const RoleChipField = ({ roles, ...props }) => {
  const classes = useStyles();
  const record = useRecordContext(props) as unknown as string;

  return (
    <Chip
      className={classes.chip}
      label={roleNames[record]}
    />
  );
};

import React from "react";
import {
  Datagrid,
  DateField,
  EditButton,
  List,
  Loading,
  ReferenceArrayField,
  TextField,
  usePermissions,
  useRedirect,
  useTranslate,
} from "react-admin";
import { hasAccess } from "../../global/permissions";
import CustomActionBar from "../Layout/CustomActionBar";
import DefaultFilter from "../Layout/Filters/DefaultFilter";
import ListLayout from "../Layout/ListLayout";

const ExperienceGroupsList = () => {
  const organization = JSON.parse(localStorage.getItem("organization"));
  const redirect = useRedirect();
  const { permissions } = usePermissions();
  const translate = useTranslate();

  if (!organization || !permissions) return <Loading />;

  if (!hasAccess({ name: "experience-groups", crudType: "list" }, permissions))
    redirect("/");

  return (
    <ListLayout title={translate("experience-groups.name")}>
      <List
        exporter={false}
        perPage={50}
        filters={<DefaultFilter />}
        sort={{ field: "updated_at", order: "DESC" }}
        actions={
          <CustomActionBar
            createButtonProps={{ label: translate("experience-groups.create") }}
          />
        }
      >
        <Datagrid bulkActionButtons={false}>
          <TextField source='id' label={translate("misc.id")} />
          <TextField source='name' label={translate("misc.name")} />
          <ReferenceArrayField
            source='experience_ids'
            reference='experiences'
            label={translate("resources.experiences")}
            sortable={false}
          />
          <DateField
            source='updated_at'
            label={translate("misc.last_update")}
            showTime
          />
          <EditButton variant='contained' icon={null} />
        </Datagrid>
      </List>
    </ListLayout>
  );
};

export default ExperienceGroupsList;

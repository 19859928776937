import React, {useEffect} from "react";
import {useCreate, useCreateSuggestionContext} from "react-admin";
import {Dialog} from "@mui/material";
import CustomLoading from "../Loading/CustomLoading";

interface AutoCreateTagProps {
    type: string;
}

const AutoCreateTag: React.FC<AutoCreateTagProps> = ({type}) => {
    const {filter, onCancel, onCreate} = useCreateSuggestionContext();
    const [value, setValue] = React.useState(filter || '');
    const [create] = useCreate('tags');

    const handleSubmit = event => {
        event.preventDefault();
        create(
            'tags',
            {
                data: {
                    name: value,
                    type
                },
            },
            {
                onSuccess: (data) => {
                    setValue('');
                    onCreate(data);
                },
            }
        );
    };

    useEffect(() => {
        handleSubmit(new Event('submit'));
    }, [])

    return (
        <Dialog open onClose={onCancel}>
            <form onSubmit={handleSubmit}>
                <CustomLoading/>
            </form>
        </Dialog>
    );
};

export default AutoCreateTag;
import {Grid, Skeleton} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import {useEffect, useState} from "react";
// import { Loading } from "react-admin";
import dataProvider from "../../data-providers/data-provider";
import {hasAccess} from "../../global/permissions";
import {IRole} from "../../models/role";
import {DashboardCard} from "../Layout/DashboardCard";

const useStyles = makeStyles({
  grid: {
    // marginTop: "1em",
  },
});

export const DashboardLastNews = ({permissions, organizationId, ...props}) => {
  const classes = useStyles();
  const [roles, setRoles] = useState<IRole[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // roles
    if (roles.length === 0)
      dataProvider
        .getList("roles")
        .then(({ data: roles }) => setRoles(roles))
        .catch((err) => console.error(err));
  }, [roles.length]);

  useEffect(() => {
    if (!permissions || roles.length === 0) setLoading(true);
    else setLoading(false);
  }, [permissions, roles.length]);

  const getMore = (resource: string) => {
    if (!organizationId) return null;
    switch (resource) {
      case "experiences":
        return "/experiences";
      case "organizations":
        return "/organizations";
      case "users":
        return `/organizations/${organizationId}/users`;
      case "licenses":
        return `/organizations/${organizationId}/licenses`;
        case "service-licenses":
            return `/organizations/${organizationId}/service-licenses`;
      default:
        return "/";
    }
  }

  return (
    <>
      <Grid container spacing={4} className={classes.grid}>
        {hasAccess({ name: "experiences", crudType: "list" }, permissions) && (
            <Grid item sm={12} lg={6}>
            {!loading ? (
              <DashboardCard
                resource="experiences"
                organizationId={organizationId}
                more={getMore("experiences")}
              />
            ) : (
              <Skeleton variant="rounded" width="100%" height={360} />
            )}
          </Grid>
        )}

        {hasAccess(
          { name: "organizations", crudType: "list" },
          permissions
        ) && organizationId === null && (
            <Grid item sm={12} lg={6}>
            {!loading ? (
              <DashboardCard
                resource="organizations"
                organizationId={organizationId}
                more={getMore("organizations")}
              />
            ) : (
              <Skeleton variant="rounded" width="100%" height={360} />
            )}
          </Grid>
        )}

        {hasAccess({ name: "users", crudType: "list" }, permissions) && (
            <Grid item sm={12} lg={6}>
            {!loading ? (
              <DashboardCard
                resource="users"
                organizationId={organizationId}
                roles={roles}
                more={getMore("users")}
              />
            ) : (
              <Skeleton variant="rounded" width="100%" height={360} />
            )}
          </Grid>
        )}

        {hasAccess({ name: "licenses", crudType: "list" }, permissions) && (
            <Grid item sm={12} lg={6}>
            {!loading ? (
              <DashboardCard
                resource="licenses"
                organizationId={organizationId}
                roles={roles}
                more={getMore("licenses")}
              />
            ) : (
              <Skeleton variant="rounded" width="100%" height={360} />
            )}
          </Grid>
        )}

          {hasAccess({name: "service-licenses", crudType: "list"}, permissions) && (
              <Grid item sm={12} lg={6}>
                  {!loading ? (
                      <DashboardCard
                          resource="service-licenses"
                          organizationId={organizationId}
                          roles={roles}
                          more={getMore("service-licenses")}
                      />
                  ) : (
                      <Skeleton variant="rounded" width="100%" height={360}/>
                  )}
              </Grid>
          )}
      </Grid>
    </>
  );
};

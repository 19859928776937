import React from "react";
import {
  Checkbox,
  Container,
  FormControlLabel,
  FormLabel,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { managerUseStyles } from "../StepperManager";
import { theme } from "../../../../global/styles";
import {
  AutocompleteArrayInput,
  BooleanInput,
  ReferenceArrayInput,
  required,
  useRecordContext,
  useTranslate,
} from "react-admin";
import FormControl from "@mui/material/FormControl";
import CustomInput from "../../../Layout/Inputs/CustomInput";
import { NameField } from "../ExperienceForm";

const TermsAndConditionsBloc = () => {
  return (
    <Paper sx={{ background: theme.palette.background.default }}>
      <Stack
        height={"20em"}
        overflow={"scroll"}
        p={4}
        gap={2}
        textAlign={"center"}
      >
        <Stack>
          <Typography variant={"h6"} fontWeight={"bold"}>
            Conditions Générales d'Utilisation
          </Typography>
          <Typography variant={"subtitle1"} fontWeight={"bold"}>
            UniVR Solutions
          </Typography>
        </Stack>
        <Typography variant={"body2"} fontWeight={500}>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
          convallis sed diam ac semper. Nullam ac imperdiet mi. Mauris vel
          rutrum nisl. Aenean efficitur nisl neque, eget posuere nunc
          ullamcorper non. Integer pretium interdum imperdiet. Pellentesque
          accumsan tortor non risus imperdiet fermentum. Nullam sodales augue
          sit amet nunc pellentesque, sed laoreet libero rhoncus. Pellentesque
          at ligula vitae mi mollis rutrum. Donec in blandit libero, id
          tincidunt nulla. Donec sed magna nec erat convallis tristique eget
          quis lectus. Aliquam finibus enim non arcu imperdiet mollis eu at
          sapien. Nunc eget sem semper, sodales nisl varius, gravida neque. Ut
          vel imperdiet dolor. Lorem ipsum dolor sit amet, consectetur
          adipiscing elit. Fusce venenatis in nisi a congue. Vestibulum
          consectetur mattis sapien, eget semper dolor. Aenean venenatis orci
          nulla, a imperdiet ante tristique ut. In hac habitasse platea
          dictumst. Pellentesque risus mi, fermentum ut justo et, auctor tempor
          orci. Quisque porta orci in massa volutpat, sed posuere mauris
          gravida. Nam dapibus aliquet orci vel placerat. Phasellus sit amet
          rhoncus erat. Suspendisse tristique efficitur erat, ut consectetur
          elit dignissim consequat. Integer iaculis dolor ullamcorper porta
          pellentesque. Duis quis neque in dui malesuada fringilla at sit amet
          magna. Nunc sem diam, consectetur id efficitur quis, fringilla eu
          sapien. Curabitur sodales magna sed massa consectetur, pellentesque
          aliquam magna imperdiet. Duis malesuada orci at ex auctor commodo.
          Aenean in elementum massa. Pellentesque non fermentum nibh. Fusce
          tristique erat dui, non feugiat purus mattis eget. Curabitur sed felis
          in est pulvinar imperdiet. Etiam leo orci, vulputate in mauris in,
          venenatis mollis velit. Vivamus id risus eget leo fringilla aliquet.
          Vestibulum faucibus, dui at rutrum accumsan, erat erat tincidunt
          tellus, eget pellentesque lorem eros eu neque. Sed suscipit ex nisl,
          at auctor erat consequat quis. Morbi venenatis dui nec arcu luctus,
          nec venenatis justo malesuada. Fusce commodo ipsum velit, eget feugiat
          elit ornare eu. Morbi efficitur aliquam erat, sed dictum est euismod
          id. Donec mattis malesuada lorem. Mauris vitae mauris eros. Donec
          pretium odio quis auctor elementum. Maecenas ornare ligula eu
          fringilla fermentum. Curabitur placerat neque vel purus laoreet
          convallis. Pellentesque nisl magna, semper et interdum a, mattis ac
          tellus. In porta pellentesque orci ullamcorper facilisis. Vivamus
          congue venenatis magna, vel interdum turpis tempor nec. Proin
          consectetur nunc molestie mauris gravida blandit. Nunc eget elit
          justo. Vivamus elementum tempor lectus, in bibendum sapien interdum
          id. Vestibulum placerat tellus vel ante pharetra, pharetra malesuada
          metus tempus. Mauris accumsan volutpat nibh et scelerisque. Vestibulum
          eget gravida dolor. Cras suscipit viverra lectus, in luctus tellus
          posuere in. Cras velit orci, hendrerit sit amet vulputate in,
          ultricies et orci. Donec consectetur dignissim aliquam. Praesent ac
          ultrices metus. Maecenas ac congue enim, sed fringilla nulla. Quisque
          dictum vestibulum justo a accumsan. Aliquam nunc metus, fringilla quis
          lorem at, tincidunt hendrerit nulla. Etiam sodales odio convallis
          tincidunt volutpat. Ut scelerisque tristique feugiat. Maecenas at enim
          mi. Nam maximus massa id justo mattis, eu faucibus mauris eleifend.
          Curabitur malesuada, nisi nec tincidunt ullamcorper, quam sapien
          lacinia metus, vitae aliquet purus mauris ac lectus. Duis purus justo,
          consequat ac sagittis nec, eleifend sit amet magna. Proin velit arcu,
          malesuada in nunc et, condimentum sagittis urna. Duis eu vulputate
          risus. Curabitur ornare ligula id ligula lobortis, vel rutrum leo
          sagittis. Quisque non quam turpis. Sed a eros vulputate, lobortis
          metus at, molestie dui. Nullam id lectus turpis. Vestibulum purus leo,
          scelerisque sed placerat vulputate, gravida nec magna. Nunc lobortis
          blandit quam, sit amet cursus nisl blandit non. Aliquam et ipsum quis
          leo finibus porttitor quis id quam. Aenean dapibus elit id arcu
          hendrerit, quis dictum risus ullamcorper. In hac habitasse platea
          dictumst. Donec sagittis nec lorem tincidunt volutpat. Nullam vehicula
          nunc a augue placerat ullamcorper. Donec congue mauris luctus
          ultricies pellentesque. Phasellus nulla magna, aliquam non sem vitae,
          hendrerit tempus neque. In hendrerit ligula vitae ipsum lobortis
          interdum. Praesent odio velit, tristique in dictum nec, vulputate ut
          felis. Vivamus et nunc ut elit tincidunt auctor porta sed purus. Sed
          malesuada dui vel nisi tempus fermentum. Vivamus ultrices vel nisi vel
          dignissim. Suspendisse potenti. Vestibulum scelerisque eros in mi
          lobortis, mattis aliquam neque faucibus. Quisque sed purus fermentum,
          vestibulum sapien at, feugiat elit. Fusce in egestas nisi. Maecenas
          mollis non nunc ut interdum. In felis sapien, auctor in malesuada sit
          amet, varius eget quam. Donec fringilla nulla in quam scelerisque
          ornare. Suspendisse rutrum purus at ex elementum, hendrerit molestie
          lacus ornare. Class aptent taciti sociosqu ad litora torquent per
          conubia nostra, per inceptos himenaeos. Ut euismod cursus mauris ac
          pulvinar. Aliquam vehicula dui sagittis nisl pulvinar tristique. Sed
          pellentesque finibus dapibus. Vivamus eleifend quam nisl, ut malesuada
          sem commodo in. Nam eu magna massa. Curabitur pretium, massa sit amet
          auctor porttitor, ligula mi convallis nibh, sed elementum augue magna
          lobortis arcu. Suspendisse sagittis quis risus id finibus. Etiam
          facilisis egestas libero et tincidunt. Etiam lacinia, tellus at varius
          malesuada, turpis diam molestie augue, tristique sagittis velit ligula
          vel metus. Sed sed sem sed nibh laoreet ornare non eget felis.
          Curabitur at ornare purus. Sed porta tristique risus sit amet mollis.
          Vestibulum non diam turpis. Mauris nibh augue, eleifend pulvinar
          aliquam non, aliquam non elit. Aliquam lobortis semper viverra.
          Curabitur elementum fringilla erat id auctor. Pellentesque quam erat,
          auctor eu accumsan a, aliquet a justo. Ut consequat lorem sit amet
          metus tempor, vel molestie justo fermentum. In maximus tincidunt mi at
          elementum. Donec imperdiet semper aliquam. Donec metus massa, pulvinar
          et sem eget, consequat condimentum nunc. Etiam vitae quam cursus,
          rutrum justo nec, vestibulum tellus. Vestibulum tincidunt congue lacus
          eget ultricies. Vivamus nec justo sed lacus rhoncus cursus. In
          molestie vehicula felis, eu vulputate elit malesuada nec. Suspendisse
          consequat eget sem eu tempus. Vestibulum non sagittis ligula, ac
          vulputate dolor. Nunc sagittis mauris dolor, at tristique velit
          dignissim ac. Duis vel accumsan enim, non tincidunt felis. Mauris sit
          amet sem metus. Nunc gravida sapien libero, ac hendrerit mauris dictum
          et. Fusce placerat nulla odio, a molestie neque placerat et. Cras eget
          ultricies turpis, quis ornare risus. Proin ac leo sapien. In sodales
          vel leo sit amet condimentum.
        </Typography>
      </Stack>
    </Paper>
  );
};

const ShareSwitchManager = () => {
  const classes = managerUseStyles();
  const record = useRecordContext();
  const [share, setShare] = React.useState<boolean>(
    !record ? true : record?.included_to_univrsity
  );

  const shareOnChange = (event) => {
    setShare(event.target.checked);
  };

  return (
    <Stack
      direction={"row"}
      justifyContent={"center"}
      alignItems={"baseline"}
      gap={4}
    >
      <p
        className={
          share
            ? classes.switchManagerTextNotShared
            : classes.switchManagerTextShared
        }
      >
        Mon organisation uniquement
      </p>
      <BooleanInput
        source='include_to_univrsity'
        onChange={shareOnChange}
        label={false}
        checked={share}
        defaultValue={true}
      />
      <p
        className={
          share
            ? classes.switchManagerTextShared
            : classes.switchManagerTextNotShared
        }
        style={{ fontWeight: "bold" }}
      >
        UniVRsity
      </p>
    </Stack>
  );
};

const ShareOptions = () => {
  const translate = useTranslate();
  return (
    <FormControl component='fieldset' variant='standard'>
      <FormLabel component='legend'>
        {translate("misc.share_options")}
      </FormLabel>
      <Stack gap={2}>
        <BooleanInput
          label='UniVRsity'
          source='included_to_univrsity'
          sx={{
            "& .MuiFormHelperText-root": {
              display: "none",
            },
            margin: 0,
          }}
        />
        <CustomInput
          inputComponent={ReferenceArrayInput}
          inputComponentProps={{
            source: "licensed_organization_ids",
            reference: "organizations",
            optionText: <NameField />,
            optionValue: "name",
            validate: required(),
            label: false,
            perPage: 1000,
            queryOptions: { meta: { useCategories: true } },
            children: (
              <AutocompleteArrayInput
                label={false}
                sx={{ minWidth: "22em" }}
                multiple
                inputText={(option) => option.name}
                matchSuggestion={(filterValue, suggestion) =>
                  suggestion.name
                    .toLowerCase()
                    .includes(filterValue.toLowerCase())
                }
                noOptionsText={translate("organizations.not_found")}
                disableCloseOnSelect
                blurOnSelect={false}
              />
            ),
          }}
          fullWidth
          label={translate("organizations.list")}
          helperText={translate("organizations.list_helper")}
          required={false}
        />
      </Stack>
    </FormControl>
  );
};

interface ManagerShareFormTabProps {
  setConsent: (consent: boolean) => void;
  consent: boolean;
}

const ManagerShareFormTab: React.FC<ManagerShareFormTabProps> = ({
  setConsent,
  consent,
}) => {
  const translate = useTranslate();
  return (
    <Container maxWidth={"md"}>
      <Stack gap={4} mt={6} mb={4}>
        {/*<ShareSwitchManager/>*/}
        <ShareOptions />
        {/*<TermsAndConditionsBloc/>*/}
        <div>
          <FormControlLabel
            control={
              <Checkbox
                color='primary'
                checked={consent}
                onChange={(e) => setConsent(e.target.checked)}
              />
            }
            label={
              <Typography>{translate("misc.share_options_helper")}</Typography>
            }
          />
        </div>
      </Stack>
    </Container>
  );
};

export default ManagerShareFormTab;

import {IResource} from "../models/resource";
import {IAPIRole, IRole, RoleID} from "../models/role";

export const hasAccess = (
    {name, crudType}: IResource,
    permissions: RoleID[]
): boolean => {
    switch (name) {
        case "experiences":
            switch (crudType) {
                case "create":
                case "delete":
                case "list":
                case "show":
                case "edit":
                default:
                    return (
                        permissions.includes("admin") ||
                        permissions.includes("contentCreator") ||
                        permissions.includes("adminOrga")
                    );
            }

        case "licenses":
            switch (crudType) {
                case "list":
                case "show":
                case "create":
                case "delete":
                case "edit":
                default:
                    return (
                        permissions.includes("admin")
                    );
            }

        case "organizations":
            switch (crudType) {
                case "edit":
                case "show":
                    return (
                        permissions.includes("admin") || permissions.includes("adminOrga")
                    );
                case "create":
                case "delete":
                case "list":
                default:
                    return permissions.includes("admin");
            }

        case "experience-groups":
            switch (crudType) {
                case "list":
                case "show":
                case "create":
                case "delete":
                case "edit":
                default:
                    return (
                        permissions.includes("admin")
                    );
            }

        case "users":
            switch (crudType) {
                case "create":
                case "delete":
                case "edit":
                case "list":
                case "show":
                default:
                    return (
                        permissions.includes("admin") || permissions.includes("adminOrga")
                    );
            }

        case "service-licenses":
            switch (crudType) {
                case "create":
                case "delete":
                case "edit":
                case "list":
                case "show":
                default:
                    return (
                        permissions.includes("admin") || permissions.includes("adminOrga")
                    );
            }

        case "home/updates":
            return permissions.includes("admin");

        case "profile":
            return true;

        case "tags":
            return permissions.includes("admin");

        case "devices":
            return permissions.includes("admin");

        case "":
            return true;

        default:
            return false;
    }
};

export const formatRolesForEdit = (roles: IAPIRole[]): IRole[] => {
    return roles.map((role) => ({
        id: role.name,
        name: getRoleDisplayName(role.name),
    }));
};

export const formatRolesForList = (roles: IAPIRole[]) => {
    return roles.map((role) => getRoleDisplayName(role.name));
};

const getRoleDisplayName = (roleID: RoleID) => {
    switch (roleID) {
        case "normal":
            return "Normal";
        case "admin":
            return "Administrateur";
        case "adminOrga":
            return "Administrateur d'Organisation";
    }
};
